import View from "../views/views.js";

const loginForm = document.querySelector(".loginOverlayContainer form");
const signupForm = document.querySelector(".signupOverlayContainer form");

const loginEmailField = loginForm?.querySelector("#email");
const loginPasswordField = loginForm?.querySelector("#password");

const signupEmailField = signupForm?.querySelector("#signupEmail");
const signupPasswordField = signupForm?.querySelector("#signupPassword");
const passwordConfirmField = signupForm?.querySelector("#passwordConfirm");

const sendLoginSignupRequest = async (url, body) => {
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    });
    const result = await res.json();

    View.showNotification("", result.message, 3, res.ok ? undefined : "error");
    if (result.status === "success") {
      document
        .querySelector(".showOverlayContainer")
        .classList.remove("showOverlayContainer");
      signupForm.reset();
      loginForm.reset();
      setTimeout(() => {
        location.reload();
      }, 5);
    }
  } catch (err) {
    console.error(err.message);
  }
};

loginForm?.addEventListener("submit", async (e) => {
  e.preventDefault();
  const emailAddress = loginEmailField.value;
  const password = loginPasswordField.value;

  await sendLoginSignupRequest(
    "/user/login",
    JSON.stringify({ emailAddress, password })
  );
});

signupForm?.addEventListener("submit", async (e) => {
  e.preventDefault();
  const emailAddress = signupEmailField.value;
  const password = signupPasswordField.value;
  const passwordConfirm = passwordConfirmField.value;

  await sendLoginSignupRequest(
    "/user/signup",
    JSON.stringify({ emailAddress, password, passwordConfirm })
  );
});
