class NavView {
  mobileNav = document.querySelector(".menuLinkContainer.mobileNav");
  closeNavMenu = document.querySelector(
    ".menuLinkContainer.mobileNav .closeNavMenu"
  );
  openNavMenu = document.querySelector(".topNav .openNavMenu");

  constructor() {
    this.controlNavMobileView();
  }

  controlNavMobileView() {
    const toggleMobileNav = () => this.mobileNav.classList.toggle("hide");
    this.closeNavMenu.addEventListener("click", toggleMobileNav);
    this.openNavMenu.addEventListener("click", toggleMobileNav);
  }
}

export default new NavView();
