import view from "../views/views.js";
import CartView from "../views/cartView.js";
import billView from "../views/billView.js";
import ShopView from "../views/shopView.js";
import * as model from "../model.js";
import views from "../views/views.js";

let eventHandlerCount = 0;

const updateSummaryView = function () {
	const billSummaryReceivedHandler = () => {
		billView.updateBillSummary(model.getBillSummary());
		if (views.openCartBtn)
			CartView.updateCartOpenItemCounter(model.getItemInCartCount());

		eventHandlerCount++;
		if (eventHandlerCount > 1)
			window.removeEventListener(
				"billSummaryRecieved",
				billSummaryReceivedHandler
			);
	};

	window.addEventListener("billSummaryRecieved", billSummaryReceivedHandler);
};

const updateCartItemCount = (itemId) => {
	CartView.updateItemCartCount(model.getItemDetails(itemId));
	updateSummaryView();
};

const loadItemToCart = (itemId, item) => {
	CartView.initializeCartItem(item);
	const itemHandlerParam = {
		increaseCartItemCount,
		decreaseCartItemCount,
		deleteCartItem,
	};
	CartView.addHandlerCartItem(itemId, itemHandlerParam);
	updateSummaryView();
};

// Function to handle adding item to the cart
export const handleAddToCart = async (itemId) => {
	const itemAddedToCart = await model.addItemToCart(itemId);
	const item = model.getItemDetails(itemId);

	if (!itemAddedToCart) return CartView.showCartMinSellError(item);

	loadItemToCart(itemId, item);

	view.showNotification(
		"Cart Updated",
		`${item.selectedItemCount} ${item.itemName} has been added to cart`,
		3
	);
};

const increaseCartItemCount = async function (itemId) {
	// Increase Cart Item Count in model
	await model.increaseCartItemCount(itemId);
	// Update Cart Item Count UI
	updateCartItemCount(itemId);
};

const decreaseCartItemCount = async function (itemId) {
	// Decrease Cart Item Count in model
	const decreaseProcess = await model.decreaseCartItemCount(itemId);

	if (!decreaseProcess) {
		view.showNotification(
			"",
			"You have selected the minimum quantity!",
			3,
			"error"
		);
	}

	// Update Cart Item Count UI
	updateCartItemCount(itemId);
};

export const deleteCartItem = async function (itemId) {
	await model.deleteCartItem(itemId);
	const item = model.getItemDetails(itemId);

	CartView.deleteCartItem(item);
	updateSummaryView();

	// Updating Item count to support minimum item order after delete
	ShopView.updateItemCount(itemId, item.selectedItemCount);
};

model.init.then(() => {
	// Get all item Id's in cart
	const itemIds = model.getItemInCartId();
	itemIds.forEach((itemId) => {
		const item = model.getItemDetails(itemId);
		loadItemToCart(itemId, item);
	});
	updateSummaryView();

	billView.btnListener(model.getItemInCartCount, model.getItemInCart);
	if (location.pathname === "/confirm-order" && model.getItemInCartCount() <= 0)
		window.location.href = "/shop";
});
