class ShopView {
	shopMenuListContainer = document.querySelector(".menuSection .menuList");
	supportedPostcodeForm = document.querySelector(".checkPostcodeSupportForm");
	supportedPostcodeFormInput = document.querySelector("#postcodeInput");

	checkSupportedPostcode(checkPostcodeSupport) {
		if (this.supportedPostcodeForm) {
			this.supportedPostcodeForm.addEventListener("submit", (e) => {
				e.preventDefault();
				const postcode = this.supportedPostcodeFormInput.value;
				checkPostcodeSupport(postcode);
			});
		}
	}

	addHandlerItemShopping(controlShopping) {
		this.shopMenuListContainer.addEventListener("click", function (e) {
			const itemShopBtns = e.target.closest(".menuItemBuyOptionContainer");
			if (!itemShopBtns) return;
			const itemId = itemShopBtns.dataset.itemId;
			const increase = e.target.closest(".addItem");
			const decrease = e.target.closest(".removeItem");
			const addToCart = e.target.closest(".addToCartBtn");
			controlShopping(itemId, increase, decrease, addToCart);
		});
	}

	updateItemCount(itemId, curItemCount) {
		const itemCountContainer = document.querySelector(
			`[data-item-id="${itemId}"]`
		);
		if (itemCountContainer)
			itemCountContainer.querySelector(".itemCount").innerHTML = curItemCount;
	}
}

export default new ShopView();
