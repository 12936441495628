import View from "./views.js";

// Add to cart
class CartView {
	cartListContainer = document.querySelector(".cartListContainer");
	cartOpenItemCounter = document.querySelector(
		".cartOpenItemCounterContainer p"
	);

	initializeCartItem(item) {
		if (!this.cartListContainer) return;
		let itemContainer = this.cartListContainer.querySelector(
			`[data-item-id="${item.itemId}"]`
		);

		// Add itemContainer to cartList if itemContainer does not exist
		if (!itemContainer) {
			const itemMarkup = this.generateCartItemMarkup(item);
			this.cartListContainer.insertAdjacentHTML("afterbegin", itemMarkup);
			itemContainer = this.cartListContainer.querySelector(
				`[data-item-id="${item.itemId}"]`
			);
			itemContainer.clickHandlerAttached = false;
		}

		this.updateItemCartCount(item);
	}

	updateCartOpenItemCounter(itemCount) {
		this.cartOpenItemCounter.innerHTML = itemCount;
	}

	updateItemCartCount(item) {
		let itemContainer = this.cartListContainer.querySelector(
			`[data-item-id="${item.itemId}"]`
		);

		// Updating itemQuantity and TotalPrice UI in itemContainer
		itemContainer.querySelector(".itemQuantity").innerHTML =
			item.itemCartQuantity;
		itemContainer.querySelector(".itemTotalPrice").innerHTML = `£${(
			+item.itemCartQuantity * +item.itemPrice
		).toFixed(2)}`;
	}

	deleteCartItem(item) {
		let itemContainer = this.cartListContainer.querySelector(
			`[data-item-id="${item.itemId}"]`
		);
		if (itemContainer) itemContainer.remove();
	}

	generateCartItemMarkup(item) {
		return `
    <div class="cartItemContainer" data-item-id="${item.itemId}">
        <img
        src="/assets/menuImages/${item.itemImg}"
        alt="${item.itemName}"
        class="cartItemImg"
        />
        <div class="itemDetailsAndControl">
        <p class="itemName">${item.itemName}</p>
        <p class="itemUnitPrice">£${Math.floor(item.itemPrice * 100) / 100}</p>
        <div class="itemQuantityControlContainer">
            <div class="cartSubtractItem">
              <svg alt="Subtract 1 item from selection">
                <use href="/assets/images/icons.svg#minus_green"></use>
              </svg>
            </div>
            <div class="itemQuantity"></div>
            <div class="cartAddItem">
              <svg alt="Add 1 item to selection">
                <use href="/assets/images/icons.svg#plus_green"></use>
              </svg>
            </div>
        </div>
        </div>
        <div class="itemPriceAndDelete">
        <p class="itemTotalPrice"></p>
        <div class="deleteCartItemBtn">
          <svg alt="Add 1 item to selection" class="deleteIcon">
                <use href="/assets/images/icons.svg#bin_green"></use>
            </svg>
            <p class="deleteText">Delete</p>
        </div>
        </div>
    </div>
    `;
	}

	addHandlerCartItem(itemId, itemHandlers) {
		if (!this.cartListContainer) return;
		const itemContainer = this.cartListContainer.querySelector(
			`[data-item-id="${itemId}"]`
		);

		// Attaching click event listener if not attached
		if (!itemContainer.clickHandlerAttached) {
			itemContainer.addEventListener("click", async function (e) {
				if (e.target.closest(".cartAddItem"))
					await itemHandlers.increaseCartItemCount(itemId);
				if (e.target.closest(".cartSubtractItem"))
					await itemHandlers.decreaseCartItemCount(itemId);
				if (e.target.closest(".deleteCartItemBtn"))
					await itemHandlers.deleteCartItem(itemId);
			});

			itemContainer.clickHandlerAttached = true;
		}
	}

	showCartMinSellError(item) {
		View.showNotification(
			"Error",
			`Can only add a minimum of ${item.minSell} ${item.itemName} to cart`,
			3,
			"error"
		);
	}
}

export default new CartView();
