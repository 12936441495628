import View from "./views.js";
const urgentCartUploadEvent = new CustomEvent("urgentCartUpload");

class BillView {
	constructor() {
		const containerSelector = ".billSection";
		const chargesSelector = ".additionalCharges";

		this.billViewContainer = document.querySelector(containerSelector);
		if (!this.billViewContainer) return;
		this.billSubtotal = this.getBillElementValue(".billSubtotal");
		this.discount = this.getBillElementValue(`${chargesSelector} .discount`);
		this.deliveryCost = this.getBillElementValue(
			`${chargesSelector} .delivery`
		);

		this.billTotal = this.getBillElementValue(".billTotalContainer .total");

		this.confirmOrderBtn = document.querySelector(
			`${containerSelector} .confirmOrderBtn`
		);
		this.continueShoppingBtn = document.querySelector(
			`${containerSelector} .continueShoppingBtn`
		);
		this.confirmAndPayBtn = document.querySelector("button.confirmAndPay");
	}

	getBillElementValue(selector) {
		return this.billViewContainer.querySelector(`${selector} .value`);
	}

	formatValue(value) {
		return `£${value.toFixed(2)}`;
	}

	updateBillSummary(billSummary) {
		const { subtotal, discount, delivery, tax } = billSummary;

		if (!this.billViewContainer) return;

		this.billSubtotal.innerHTML = this.formatValue(subtotal);
		this.discount.innerHTML = this.formatValue(discount);
		this.deliveryCost.innerHTML = this.formatValue(delivery);

		const totalAmount = subtotal - discount + delivery;
		this.billTotal.innerHTML = this.formatValue(totalAmount);
	}

	btnListener(getItemsInCartLength, getItemInCart) {
		this.confirmOrderBtn.addEventListener("click", () => {
			if (getItemsInCartLength() > 0) {
				window.location.href = "/confirm-order ";
				return;
			}

			return View.showNotification(
				"",
				`Your cart is empty. Please add some item to cart at <a href="/shop">shop</a>`,
				4,
				"error"
			);
		});

		this.continueShoppingBtn.addEventListener("click", () => {
			window.location.href = "/shop";
		});

		const profileForm = document.querySelector("#profileCheckoutForm");
		if (profileForm)
			profileForm.addEventListener("submit", (e) => {
				e.preventDefault();

				window.dispatchEvent(urgentCartUploadEvent);
				window.addEventListener("urgentCartUploadCompleted", async () => {
					try {
						const fields = Object.values(e.target.elements);
						const profileFormData = {};

						fields.forEach((field, index) =>
							field.name && field.value.trim() !== ""
								? (profileFormData[field.name] = field.value)
								: undefined
						);

						const cart = getItemInCart();

						const response = await fetch("/order/checkout", {
							method: "POST",
							headers: { "Content-Type": "application/json" },
							body: JSON.stringify({ profileData: profileFormData, cart }),
						});
						const result = await response.json();

						if (!response.ok) throw new Error(result.message);

						View.showNotification("Success", result.message, 5);
						setTimeout(() => {
							location.href = result.data;
						}, 5);
					} catch (err) {
						console.error(err);
						View.showNotification("Error", err.message, 5, "error");
					}
				});
			});
	}
}

export default new BillView();
